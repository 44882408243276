import {Component, Prop, Vue} from 'vue-property-decorator';
import AuktionsService from "@/services/AuktionsService";
import {Auktion} from "@/definitions/Auktion";


@Component({})
export default class SinconaBasis extends Vue {
  private auktionsService = new AuktionsService();
  public auktionen: Auktion[] | null = null;

  resource : any = {};
  @Prop({required: true}) readonly language!: string;
  @Prop({required: true}) readonly apiUrl!: string;
  @Prop({required: false}) readonly method!: string;
  @Prop({required: false}) readonly dateFrom!: string;
  @Prop({required: false}) readonly schwerpunktIds!: string;
  @Prop({required: false}) readonly auktionsarten!: string;

  public mounted() {
    if (this.language == 'de') {
      this.resource = {
        aktuelleAuktionen: 'Aktuelle Auktionen',
        loseAnzeigen: 'Lose anzeigen',
        nachverkauf: 'Unverkaufte Lose anzeigen',
        vorabgebote: 'Lose anzeigen & Gebote abgeben',
        auktionsPdf: 'Auktionskatalog als PDF',
        ergebnisPdf: 'Ergebnisliste als PDF',
        zeitzone: 'MEZ',
        sommerzeitzone: 'MESZ',
        datum:'Datum',
        auktion:'Auktion',
        auktionsname:'Auktionsname',
        information:'Informationen',
        livebidding:'Live Bidding',
        eauction:'LiveAuction',
        saalauktion:'Saalauktion',
        onlinekatalog:'Online Katalog',
        druckkatalog:'Katalog als PDF',
        ergebnisliste:'Ergebnisliste',
        legende:'Legende'
      };
    } else {
      this.resource = {
        aktuelleAuktionen: 'Current auctions',
        loseAnzeigen: 'View lots',
        nachverkauf: 'Show unsold lots',
        vorabgebote: 'View lots & place bids',
        auktionsPdf: 'Auction catalogue in PDF',
        ergebnisPdf: 'List of prices realized in PDF',
        zeitzone: 'CET',
        sommerzeitzone: 'CEST',
        datum:'Date',
        auktion:'Auction',
        auktionsname:'Auction name',
        information:'Information',
        livebidding:'Live Bidding',
        eauction:'LiveAuction',
        saalauktion:'Floor auction',
        onlinekatalog:'Online catalogue',
        druckkatalog:'Catalogue as PDF',
        ergebnisliste:'List of prices realized',
        legende:'Legend'
      }
    }
    if(this.method == "archiv"){
      this.auktionsService.listeArchiv(this.apiUrl, {
        language: this.language,
        auexId: 4,
        schwerpunktIds: this.schwerpunktIds,
        auktionsarten: this.auktionsarten,
        dateFrom: this.dateFrom
      }).then(r => this.auktionen = r);
    }
    else {
      this.auktionsService.listeAktive(this.apiUrl, {
        language: this.language,
        auexId: 4,
        schwerpunktIds: this.schwerpunktIds,
        auktionsarten: this.auktionsarten,
        dateFrom: this.dateFrom
      }).then(r => this.auktionen = r);
    }
  }

  public getUhrzeitString(auktion: Auktion) {
    return auktion.uhrzeit + " " + (auktion.sommerzeit ? this.resource.sommerzeitzone : this.resource.zeitzone);
  }

  public getOnlineKatalogLink(auktion: Auktion) {
    return "https://auktionen.sincona.com/Auktion/Onlinekatalog?lang=" + this.language + "&intAuktionsId=" + auktion.auktionsId;
  }

  public getLiveBiddingLink(auktion: Auktion) {
    return "https://auktionen.sincona.com/live/?id=" + auktion.auktionsId;
  }


  public getHref(auktion: Auktion) {
    if (auktion.status === 0 /*unbekannt*/ || auktion.auktionsArt === 5 /*keinKatalog*/) {
      //Das wurde so aus den XSLts übernommen, hintergrund unbekannt
      if (auktion.docname != "")
        return "https://auktionen.sincona.com/Auktion/Archiv";
      else
        return "https://auktionen.sincona.com/"
    } else {
      return this.getOnlineKatalogLink(auktion);
    }
  }
}