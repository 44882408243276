














































import {Component, Prop} from 'vue-property-decorator';
import LosBasis from "@/LosBasis.ts";


@Component({})
export default class LosCarousel3 extends LosBasis {
  resource = {};
  @Prop({required: false, default: 5000}) readonly interval!: number;
  public responsiveOptions = [
    {
      breakpoint: '900px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '600px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  updated() {
    const a = window as any;
    if (a.initFancybox) {
      a.initFancybox();
    }
  }

  mounted() {
    if (this.language === 'de') {
      this.resource = {
        schaetzpreis: 'Schätzpreis',
        zuschlag: 'Zuschlag',
        onlinelink: 'Losansicht im Onlinekatalog',
      }
    } else {
      this.resource = {
        schaetzpreis: 'Estim. Price',
        zuschlag: 'Result',
        onlinelink: 'Lotview in the online catalog',
      }
    }

  }

  getUrl(los: { auktionsStatus: number, id: number, losNr: number, auktionsId: number }) {
    const baseUrl = this.auexUrl + (los.auktionsStatus === 5 ? '/Auktion/KatalogArchiv?' : los.auktionsStatus === 10 ? '/Shop/Index?' : '/Auktion/Onlinekatalog?');
    const parameters = los.auktionsStatus === 10
        ? 'lang=' + this.language + '&los=' + los.id
        : 'lang=' + this.language + '&intAuktionsId=' + los.auktionsId + '&losnr=' + los.losNr;
    return baseUrl + parameters;
  }
}
