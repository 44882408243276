import Vue from 'vue'

import Carousel from "primevue/carousel";
import Galleria from 'primevue/galleria';

Vue.component('Carousel', Carousel);
Vue.component('Galleria', Galleria);

import '@/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import MenuSincona from "@/MenuSincona.vue";
import CarouselSincona from "@/CarouselSincona.vue";
import ArchivSincona from "@/ArchivSincona.vue";
import AutkionsListeArchiv1 from "@/AutkionsListeArchiv1.vue";
import AuktionsListeAktive1 from "@/AuktionsListeAktive1.vue";
import AuktionsListeAktive2 from "@/AuktionsListeAktive2.vue";
import LosCarousel1 from "@/LosCarousel1.vue";
import LosCarousel2 from "@/LosCarousel2.vue";
import LosListe1 from "@/LosListe1.vue";
import LosCarousel3 from "@/LosCarousel3.vue";

Vue.config.productionTip = false;


const losCarousels1 = document.getElementsByName('auex_losCarousel1');
if (losCarousels1 && losCarousels1.length > 0) {
  while(losCarousels1.length > 0){
    const m = losCarousels1[0];
    new LosCarousel1({
      propsData: Object.assign({}, m.dataset),
      el: m
    });
  }
}

const losCarousels2 = document.getElementsByName('auex_losCarousel2');
if (losCarousels2 && losCarousels2.length > 0) {
  while(losCarousels2.length > 0){
    const m = losCarousels2[0];
    new LosCarousel2({
      propsData: Object.assign({}, m.dataset),
      el: m
    });
  }
}

const losCarousels3 = document.getElementsByName('auex_losCarousel3');
if (losCarousels3 && losCarousels3.length > 0) {
  while(losCarousels3.length > 0){
    const m = losCarousels3[0];
    new LosCarousel3({
      propsData: Object.assign({}, m.dataset),
      el: m
    });
  }
}


const losListe1 = document.getElementsByName('auex_losListe1');
if (losListe1 && losListe1.length > 0) {
  while(losListe1.length > 0){
    const m = losListe1[0];
    new LosListe1({
      propsData: Object.assign({}, m.dataset),
      el: m
    });
  }
}

const auktionsListeAktive1 = document.getElementsByName('auex_auktionsListeAktive1');
if (auktionsListeAktive1 && auktionsListeAktive1.length > 0) {
  while(auktionsListeAktive1.length > 0){
    const m = auktionsListeAktive1[0];
    new AuktionsListeAktive1({
      propsData: Object.assign({}, m.dataset),
      el: m
    });
  }
}

const auktionsListeAktive2 = document.getElementsByName('auex_auktionsListeAktive2');
if (auktionsListeAktive2 && auktionsListeAktive2.length > 0) {
  while(auktionsListeAktive2.length > 0){
    const m = auktionsListeAktive2[0];
    new AuktionsListeAktive2({
      propsData: Object.assign({}, m.dataset),
      el: m
    });
  }
}

const auktionsListeArchiv1 = document.getElementsByName('auex_auktionsListeArchiv1');
if (auktionsListeArchiv1 && auktionsListeArchiv1.length > 0) {
  while(auktionsListeArchiv1.length > 0){
    const m = auktionsListeArchiv1[0];
    new AutkionsListeArchiv1({
      propsData: Object.assign({}, m.dataset),
      el: m
    });
  }
}



const menusSincona = document.getElementsByName('menuSincona');
if (menusSincona && menusSincona.length > 0) {
  while(menusSincona.length > 0){
    const m = menusSincona[0];
    new MenuSincona({
      propsData: Object.assign({}, m.dataset),
      el: m
    });
  }
}

const carouselSincona = document.getElementById('carouselSincona');
if (carouselSincona) {
  new CarouselSincona({
    propsData: Object.assign({}, carouselSincona.dataset),
    el: carouselSincona
  });
}

const archivSincona = document.getElementById('archivSincona');
if (archivSincona) {
  new ArchivSincona({
    propsData: Object.assign({}, archivSincona.dataset),
    el: archivSincona
  });
}
