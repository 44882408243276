import axios from 'axios';

export default class LoseService {

  public getLose(url: string, request: any) {
    return axios
      .post(url + '/api/lose/GetLoseForSnippet', request)
      .then((res) => res.data);
  }
}
