
































































import {Component} from 'vue-property-decorator';
import LosBasis from "@/LosBasis.ts";


@Component({})
export default class LosListe1 extends LosBasis {
  public mounted() {
  }

  zoom(a: number) {
    ((this.$refs[a] as Element[]) [0] as HTMLAnchorElement).click();
  }
}
