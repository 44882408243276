import axios from 'axios';
import {Auktion} from "@/definitions/Auktion";
import {AuktionsRequest} from "@/definitions/AuktionsRequest";

export default class AuktionsService {

  public listeAktive(url: string, request: AuktionsRequest) {
    return axios
      .post(url + '/api/auktionen/ListeAktive', request)
      .then((res) => <Auktion[]>res.data);
  }

  public listeArchiv(url: string, request: AuktionsRequest){
    return axios
      .post(url + '/api/auktionen/ListeArchiv', request)
      .then((res) => <Auktion[]>res.data);
  }
}
