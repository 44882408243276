import { render, staticRenderFns } from "./ArchivSincona.vue?vue&type=template&id=6219c2c2&scoped=true&"
import script from "./ArchivSincona.vue?vue&type=script&lang=ts&"
export * from "./ArchivSincona.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6219c2c2",
  null
  
)

export default component.exports