





































































import {Component, Prop} from 'vue-property-decorator';
import SinconaBasis from "./SinconaBasis";


@Component({})
export default class CarouselSincona extends SinconaBasis {
  public responsiveOptions = [
    {
      breakpoint: '900px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '600px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  @Prop({required: false, default: 5000}) readonly interval!: number;

}
