

















import {Component} from "vue-property-decorator";
import SinconaBasis from "@/SinconaBasis";

@Component({})
export default class MenuSincona extends SinconaBasis {
}
