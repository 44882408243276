















import {Component, Prop, Vue} from "vue-property-decorator";
import AuktionsService from "./services/AuktionsService";
import {Auktion} from "./definitions/Auktion";

@Component({})
export default class AuktionsListeAktive2 extends Vue {
  private auktionsService = new AuktionsService();
  public auktionen: Auktion[] | null = null;

  resource: { aktuelleAuktionen: string; zeitzone: string; sommerzeitzone: string } | null = null;
  @Prop({required: true}) readonly language!: string;
  @Prop({required: true}) readonly apiUrl!: string;
  @Prop({required: true}) readonly auexId!: number;
  @Prop({required: true}) readonly auktionsUrl!: number;
  @Prop({required: false}) readonly schwerpunktIds!: string;
  @Prop({required: false}) readonly auktionsarten!: string;


  public mounted() {
    if (this.language == 'de') {
      this.resource = {
        aktuelleAuktionen: 'Aktuelle Auktionen',
        zeitzone: 'MEZ',
        sommerzeitzone: 'MESZ',
      };
    } else {
      this.resource = {
        aktuelleAuktionen: 'Current auctions',
        zeitzone: 'CET',
        sommerzeitzone: 'CEST',

      }
    }
    this.auktionsService.listeAktive(this.apiUrl, {
      language: this.language,
      auexId: this.auexId,
      schwerpunktIds: this.schwerpunktIds,
      auktionsarten:this.auktionsarten
    }).then(r => this.auktionen = r);
  }

  public getHref(auktion: Auktion) {
    if (auktion.status === 0 /*unbekannt*/ || auktion.auktionsArt === 5 /*keinKatalog*/) {
      //Das wurde so aus den XSLts übernommen, hintergrund unbekannt
      if (auktion.docname != "")
        return this.auktionsUrl + "/Auktion/Archiv";
      else
        return auktion.katalogPdfPfad;
    } else {
      return this.getOnlineKatalogLink(auktion);
    }
  }

  public getOnlineKatalogLink(auktion: Auktion) {
    return this.auktionsUrl + "/Auktion/Onlinekatalog?lang=" + this.language + "&intAuktionsId=" + auktion.auktionsId;
  }

  public getUhrzeitString(auktion: Auktion) {
    return auktion.uhrzeit + " " + (auktion.sommerzeit ? this.resource?.sommerzeitzone : this.resource?.zeitzone);
  }
}
