import {Prop, Vue} from "vue-property-decorator";
import LoseService from "./services/LoseService";
import Component from "vue-class-component";

@Component({})
export default class LosBasis extends Vue {
  public lose: any = null;
  private loseService = new LoseService();

  @Prop({required: true}) readonly auexUrl!: string;
  @Prop({required: true}) readonly apiUrl!: string;
  @Prop({required: true}) readonly title!: string;
  @Prop({required: true}) readonly auexId!: number;
  @Prop({required: true}) readonly language!: string;
  @Prop({required: false}) readonly auktionsId!: number;
  @Prop({required: false}) readonly lagerNummer!: number;
  @Prop({required: false}) readonly losVon!: number;
  @Prop({required: false}) readonly losBis!: number;
  @Prop({required: false}) readonly topLose!: boolean;
  @Prop({required: false}) readonly highlightLose!: boolean;
  @Prop({required: false}) readonly nurUnverkauft!: boolean;
  @Prop({required: false}) readonly zufallsSortierung!: boolean;
  @Prop({required: false}) readonly losNummern!: string;
  @Prop({required: true, default: ''}) readonly platzhalterImg!: string;
  @Prop({required: false, default: 5000}) readonly interval!: number;

  public mounted() {
    const request = {
      auexId: this.auexId,
      language: this.language,
      auktionsId: this.auktionsId,
      lagerNummer: this.lagerNummer,
      losVon: this.losVon,
      losBis: this.losBis,
      topLose: this.topLose,
      highlightLose: this.highlightLose,
      nurUnverkauft: this.nurUnverkauft,
      zufallsSortierung: this.zufallsSortierung,
      losNummern: this.losNummern
    };


    this.loseService.getLose(this.apiUrl, request).then((l) => {
      this.lose = l;
    });
  }

}
