

























































































































import {Component, Prop, Vue} from "vue-property-decorator";
import AuktionsService from "@/services/AuktionsService";
import {Auktion} from "@/definitions/Auktion";

@Component({})
export default class AutkionsListeArchiv1 extends Vue {
  private auktionsService = new AuktionsService();
  public auktionen: Auktion[] | null = null;

  resource = {};
  @Prop({required: true}) readonly language!: string;
  @Prop({required: true}) readonly apiUrl!: string;
  @Prop({required: true}) readonly url!: string;
  @Prop({required: false}) readonly imageUrl!: string;
  @Prop({required: true}) readonly auexId!: number;
  @Prop({required: false}) readonly dateFrom!: string;
  @Prop({required: false}) readonly schwerpunktIds!: string;
  @Prop({required: false}) readonly auktionsarten!: string;

  public mounted() {
    if (this.language == 'de') {
      this.resource = {
        datum: 'Datum',
        auktion: 'Auktion',
        auktionsname: 'Auktionsname',
        information: 'Informationen',
        livebidding: 'Live Bidding',
        eauction: 'LiveAuction',
        saalauktion: 'Saalauktion',
        onlinekatalog: 'Online Katalog',
        druckkatalog: 'Katalog als PDF',
        ergebnisliste: 'Ergebnisliste',
        legende: 'Legende'
      };
    } else {
      this.resource = {
        datum: 'Date',
        auktion: 'Auction',
        auktionsname: 'Auction name',
        information: 'Information',
        livebidding: 'Live Bidding',
        eauction: 'LiveAuction',
        saalauktion: 'Floor auction',
        onlinekatalog: 'Online catalogue',
        druckkatalog: 'Catalogue as PDF',
        ergebnisliste: 'List of prices realized',
        legende: 'Legend'
      }
    }
    this.auktionsService.listeArchiv(this.apiUrl, {
      language: this.language,
      auexId: this.auexId,
      schwerpunktIds: this.schwerpunktIds,
      auktionsarten: this.auktionsarten,
      dateFrom: this.dateFrom
    }).then(r => this.auktionen = r);
  }

}
