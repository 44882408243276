

















































import {Component, Prop, Vue} from "vue-property-decorator";
import AuktionsService from "./services/AuktionsService";
import {Auktion} from "./definitions/Auktion";

@Component({})
export default class AuktionsListeAktive1 extends Vue {
  private auktionsService = new AuktionsService();
  public auktionen: Auktion[] | null = null;

  resource: {
    nachverkauf: string;
    vorabgebote: string;
    loseAnzeigen: string;
    auktionsKatalogAlsPdf: string;
    ergebnislisteAlsPDF: string;
  } | null = null;
  @Prop({required: true}) readonly language!: string;
  @Prop({required: true}) readonly apiUrl!: string;
  @Prop({required: true}) readonly auexId!: number;
  @Prop({required: true}) readonly auktionsUrl!: number;
  @Prop({required: false}) readonly schwerpunktIds!: string;
  @Prop({required: false}) readonly auktionsarten!: string;


  public mounted() {
    if (this.language == 'de') {
      this.resource = {
        nachverkauf: 'Unverkaufte Lose anzeigen',
        vorabgebote: 'Lose anzeigen & Gebote abgeben',
        loseAnzeigen: 'Lose anzeigen',
        auktionsKatalogAlsPdf: 'Auktionskatalog als PDF',
        ergebnislisteAlsPDF: 'Ergebnisliste als PDF'
      };
    } else {
      this.resource = {
        nachverkauf: 'Show unsold lots',
        vorabgebote: 'View lots & place bids',
        loseAnzeigen: 'View lots',
        auktionsKatalogAlsPdf: 'Auction catalogue in PDF',
        ergebnislisteAlsPDF: 'List of prices realized in PDF'
      }
    }
    this.auktionsService.listeAktive(this.apiUrl, {
      language: this.language,
      auexId: this.auexId,
      schwerpunktIds: this.schwerpunktIds,
      auktionsarten:this.auktionsarten
    }).then(r => this.auktionen = r);
  }
}
