

















































import {Component, Prop} from 'vue-property-decorator';
import LosBasis from "@/LosBasis.ts";


@Component({})
export default class LosCarousel1 extends LosBasis {
  public responsiveOptions = [
    {
      breakpoint: '900px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '600px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  @Prop({required: false, default: 5000}) readonly interval!: number;

}
